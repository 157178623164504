<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="400">
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on">
          <v-list-item-title class="text-left">View Statment</v-list-item-title>
        </v-list-item>
      </template>

      <v-card>
        <ModelTitle title="Select Date" @close="dialog = false" />

        <v-container grid-list-lg>
          <DateTimePicker
            defaultRange="thisYearToDate"
            :range="true"
            :hideTime="true"
            v-model="dateTime"
          />
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createReport()">
            Create Report
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const DateTimePicker = () => import("@/components/DateTimePicker");
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  props: ["CustomerId"],
  data() {
    return {
      dialog: false,
      dateTime: {
        start: this.$moment()
          .startOf("day")
          .toISOString(),
        end: this.$moment()
          .endOf("day")
          .toISOString(),
      },
    };
  },
  components: {
    DateTimePicker,
  },
  methods: {
    createReport() {
      const hashId = hashids.encode(this.CustomerId);
      const routeData = this.$router.resolve({
        path: `/global/statement/${hashId}/view?start=${this.dateTime.start}&end=${this.dateTime.end}`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
